.container__roadmap {
    width: 100%;
    height: 100%;
    margin-top: 10rem;
    padding-bottom: 10rem;
}


/* ==================== MEDIA QUERY (MEDIUM DEVICES) ==================== */

@media screen and (max-width: 1024px) {

    .container__roadmap {
        width: 85%;
        margin-left: 5%;
    }
}

/* ==================== MEDIA QUERY (SMALL DEVICES) ==================== */

@media screen and (max-width: 640px) { 

    .container__roadmap { 
        width: 95%;
        margin-left: 2.5%;
        margin-top: 10rem;
    }

}