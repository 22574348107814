footer {
    text-align: center;
    font-size: 0.7rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 2rem;
    display: grid;
    place-items: center;
    background: linear-gradient(to right, transparent, black, transparent);
}

.container__footer {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 99;
}

@media screen and (max-width: 640px) {

footer {
    height: 1.2rem;
    font-size: 0.4rem;
}

}
