nav {
  height: 5rem;
  width: 100vw;
  background-color: transparent;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

/* ONLY SHOWS ON MEDIUM AND SMALL DEVICES */

.nav__toggle-btn {
  display: none;
}

/********/

.nav__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: var(--container-width-lg);
  margin: 0 auto;
}


.nav__logo {
  width: 5rem;
}

.nav__socials {
  width: 2rem;
  display: flex;
  gap: 1.5rem;
}

.nav__links {
  display: flex;
  gap: 3rem;
  font-size: 1.5rem;
}

.nav__links a {
  transition: var(--transition);
  aspect-ratio: 1/1;
  color: #c0c0c0;
}

.nav__links a:hover {
  color: var(--color-hover);
}

.active-nav {
  position: relative;
}

.active-nav::after {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #c0c0c0;
  position: absolute;
  left: calc(50% - 0.6rem);
  transform: rotate(45deg);
}

/* ==================== MEDIA QUERY MEDIUM/SMALL DEVICES ==================== */

@media screen and (max-width: 1024px) {

    .nav__logo {
      width: 4rem;
    }

    .nav__socials {
      width: 2rem;
      gap: 3rem;
      margin-left: -2rem;
    }
    
    .nav__toggle-btn {
      display: inline-block;
      background: transparent;
      font-size: 1.8rem;
      cursor: pointer;
    }

    .nav__toggle-btn svg {
      color: #c0c0c0;
    }

    .nav__links {
      position: absolute;
      z-index: 99;
      top: 100%;
      right: 0;
      flex-direction: column;
      gap: 0; 
      perspective: 200px;
      font-size: 1rem;
    }

    .active-nav .active-nav::after {
      display: none;
    }

    .nav__links li {
      height: 4rem;
      width: 100%;
      box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
      animation: navAnimation 600ms ease forwards;
      transform: rotateX(90deg);
      opacity: 0;
      transform-origin: top;
    }

    .nav__links li:nth-child(2) {
      animation-delay: 200ms;
    }

    .nav__links li:nth-child(3) {
      animation-delay: 400ms;
    }

    .nav__links li:nth-child(4) {
      animation-delay: 600ms;
    }

    .nav__links li:nth-child(5) {
      animation-delay: 800ms;
    }

    @keyframes navAnimation {
      to {
        transform: rotateX(0);
        opacity: 1;
      }
    }

    .nav__links li a {
      background-color: #000000;
      color: var(--color-yellow);
      height: 100%; 
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 5rem 1rem 3rem;
    }

    .active-nav::after {
      display: none;
    }

    .show__nav {
      display: flex;
    }

    .hide__nav {
      display: none;
    }
}


@media screen and (max-width: 640px) {

  .nav__logo {
    width: 3rem;
  }

  .nav__socials {
    width: 1.5rem;
    margin-left: -1rem;
  }

}