.container__faq {
    position: relative;
    width: 100%;
    height: 100%;
}

.faqs {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    /*min-height: 50rem;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
}

.faqs__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
    width: 50rem;
    /*margin-top: -6rem;*/
    margin-top: 2.5rem;
}

.faq {
    background: linear-gradient(rgba(189,189,189, 0.8), rgba(202, 202, 202, 0.5));
    padding: 1rem;
    border: 1px solid #000000;
    border-radius: 1rem;
    cursor: pointer;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: #000000;
    font-size: 1.8rem;
}

.faq h3 {
    font-size: 1.8rem;
    font-weight: 700;
}

.faq p {
    margin-top: 1.5rem;
    font-size: 1.3rem;
    text-align: justify;
    color: #000000;
}


@media screen and (max-width: 1700px) { 
    .faqs {
        transform: translate(-50%, -55%);
    }

}

@media screen and (max-width: 1550px) { 
    .faqs {
        transform: translate(-50%, -50%);
    }

}

@media screen and (max-width: 1400px) { 
    .faqs {
        transform: translate(-50%, -45%);
    }

}

@media screen and (max-width: 1300px) { 
    .faqs {
        transform: translate(-50%, -40%);
    }

}

@media screen and (max-width: 1100px) { 
    .faqs {
        transform: translate(-50%, -35%);
    }

}

@media screen and (max-width: 1024px) { 

    .faqs {
        transform: translate(-50%, -25%);
    }

    .faqs__wrapper {
        width: 40rem;
    }
    
    .faq h3 {
        font-size: 1.6rem;
        font-weight: 400;
    }
    
    .faq p {
        font-size: 1.2rem;
    }

}

/*@media screen and (max-width: 900px) { 
    
    .faqs {
        transform: translate(-50%, -25%);
    }

}*/

@media screen and (max-width: 750px) { 
    .faqs {
        transform: translate(-50%, 5%);
    }

    .container__faq video {
        display: none;
    }

}

@media screen and (max-width: 640px) {

    .faqs__wrapper {
       width: 18rem;
    }
    
    .faq h3 {
        font-size: 1.1rem;
        font-weight: 400;
    }
    
    .faq p {
        font-size: 1rem;
    }
    
    .faq__icon {
        font-size: 1.2rem;
    }
        
    }