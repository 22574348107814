.container__team {
  width: 100%;
  height: 100%;
}

.wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.card {
  width: 20rem;
  height: 20rem;
  background: rgb(222,222,222);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 1.5rem;
  transition: 0.5s ease-in-out;
}

.card-2 {
  width: 20rem;
  height: 20rem;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 1.5rem;
}

.card:hover {
  transform: translateY(1rem);
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(189,189,189, 0.8), rgba(202, 202, 202, 0.5));
  z-index: 2;
  transition: 0.5s all;
  opacity: 0;
  border-radius: 1.5rem;
 }

 .card:hover:before {
  opacity: 1;
 }

 .card img {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
}

.card .info {
  position: relative;
  z-index: 3;
  opacity: 0;
  transform: translateY(1.5rem);
  transition: 0.5s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .info-2 {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.card .info h3 {
  margin: 0;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.card-2 .info-2 h3 {
  color: var(--color-main);
  text-align: center;
}

.card .info p {
  letter-spacing: 0.5rem;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  color: #000000;
  font-weight: 500;
}

.card__p {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper__box {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 30rem;
}

.wrapper__title {
  font-size: 1.8rem;
  text-align: center;
}

  /* ==================== MEDIA QUERY (MEDIUM DEVICES) ==================== */

@media screen and (max-width: 1024px) {

  .wrapper { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
}

  /* ==================== MEDIA QUERY (SMALL DEVICES) ==================== */

  
@media screen and (max-width: 640px) {

  .wrapper {
    width: 95%;
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-left: 2.5%;
  }

  .card {
    width: 90%;
  }

  .card-2 {
    width: 90%;
    padding-bottom: 5rem;
  }

  .card-2 .info-2 h3 {
    font-size: 1.2rem;
  }

}