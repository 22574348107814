@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    /*border: 1px solid #ccc;*/
}

:root {
    --color-main: #c0c0c0;
    --color-bgtext: rgba(111, 111, 111, 0.342);
    --color-bgtext-nt: #000000;
    --color-filter: rgba(0, 0, 0, 0.6);
    --color-filter2: rgba(0, 0, 0, 0.8);
    --color-shadow: #bdbdbd;
    --color-hover: #3c3c3c;

    --transition: all 500ms ease;

    --container-width-lg: 80%;
    --container-width-md: 90%;
    --container-width-sm: 95%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Macondo', 'cursive', 'Arial', 'sans-serif';
    background: #000000;
    color: var(--color-main);
    line-height: 1.7;
    overflow-x: hidden;
}

/* ==================== GENERAL STYLES ====================  */

h1 {
    font-family: 'Cabin Sketch', 'cursive', 'Arial', 'sans-serif';
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--color-main);
}

h2 {
    font-family: 'Cabin Sketch', 'cursive', 'Arial', 'sans-serif';
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    color: var(--color-main);
    padding-bottom: 2rem;
}

h3 {
    font-family: 'Cabin Sketch', 'cursive', 'Arial', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
}

p {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
    color: var(--color-main);
    padding-bottom: 0.3rem;
}

a {
    color: var(--color-main);
    transition: var(--transition);
}

a:hover {
    color: var(--color-hover);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}
