.header__container {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .header__text {
    position: absolute;
    z-index: 2;
    width: 80%;
    max-width: 80rem;
    /*min-height: 50rem;*/
    /*height: 80%;*/
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-content: center;
    gap: 2rem;
  }
  
  .header__title {
    filter: url('#fire');
    text-shadow: 7px 7px 12px var(--color-shadow);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header__title svg {
    display: none;
  }
  
  .header__p {
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header__p p {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1300px) {
    
    .header__text {
      transform: translate(-50%, -40%);
    }

  }

  /*@media screen and (max-width: 1100px) { 

    .header__text {
      transform: translate(-50%, -45%);
    }

  }*/
  
  @media screen and (max-width: 1024px) {

    .header__text {
      width: 85%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4rem;
    }
  
    .header__title h1 {
        font-size: 3rem;
      }
  
  }

  @media screen and (max-width: 665px) { 

    /*.header__text {
      transform: translate(-50%, -40%);
    }*/

    .header__container video {
      display: none;
    }

  }
  
  @media screen and (max-width: 640px) {

    .header__text {
      width: 90%;
      transform: translate(-50%, -35%);
    }
  
    .header__title h1 {
      font-size: 2rem;
    }
  
    .header__p p {
      font-size: 1rem;
    }
  
  }

  @media screen and (max-width: 480px) {

    .header__text {
      transform: translate(-50%, -25%);
    }
  }