.container__mission {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mission__text {
    width: 70%;
    height: 100%;
    margin-top: 15%;
    margin-bottom: 10rem;
}

.mission__text p {
    padding-bottom: 1.5rem;
}

/* ==================== MEDIA QUERY (MEDIUM DEVICES) ==================== */

@media screen and (max-width: 1024px) {

    .mission__text {
        width: 85%;
        padding-bottom: 5rem;
    }
} 

/* ==================== MEDIA QUERY (SMALL DEVICES) ==================== */

@media screen and (max-width: 640px) {

.mission__text {
    width: 90%;
    margin-top: 10rem;
}

.mission__text p {
    font-size: 1.2rem;
}
   
}